




















import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../../abstract/ui'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { MegaMenu, MegaMenuProps, translateNavigationItemToMegaMenuProps } from '../../../../front/shared/organisms/MegaMenu'
import { Navbar } from '../../../../front/shared/organisms/Navbar'
import { toImageProps } from '../../../../front/shared/support'

import { NavigationItem } from '../../../../contexts'

import { NavbarModule } from '../../Navbar.contracts'
import { UiMarkdown } from '../../../partials/UiMarkdown'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'Default',
  components: { MegaMenu, Navbar, UiMarkdown }
})
export class Default extends AbstractModuleUi<NavbarModule> {
  /**
   * TMP mounted data
   */
  public get infoBar () {
    if (this.data.content.infoBarContent) {
      return this.data.content.infoBarContent
    }

    return this.$t('modules.Navbar.infobar.default').toString()
  }

  public get icon (): string {
    return typeof this.data.content.icon === 'undefined' || !this.data.content.icon ? undefined
      : this.data.content.icon
  }

  /**
   * Determines brand image (logo) of the service
   */
  public get brandImage (): ImageProps | undefined {
    return typeof this.data.content.brandImage === 'undefined' || !this.data.content.brandImage ? undefined
      : toImageProps(this.data.content.brandImage)
  }

  /**
   * Determines secondary brand image (logo) of the service
   */
  public get secondaryBrandImage (): ImageProps | undefined {
    return typeof this.data.content.secondaryBrandImage === 'undefined' || !this.data.content.secondaryBrandImage ? undefined
      : toImageProps(this.data.content.secondaryBrandImage)
  }

  public get hasMainLinks (): boolean {
    return this.data.content.mainNav !== null || typeof this.data.content.mainNav !== 'undefined'
  }

  /**
   * @override
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasMainLinks
  }

  public buildMegaMenu (item: NavigationItem): MegaMenuProps {
    return translateNavigationItemToMegaMenuProps(item)
  }
}

export default Default
