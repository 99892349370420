














import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({ name: 'MegaMenuPagination' })
export class MegaMenuPagination extends Vue {
  public handleClick (eventName: string): void {
    this.$emit(eventName)
  }
}

export default MegaMenuPagination
